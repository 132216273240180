import React from "react";
import SvgLoader from "app.components/Util/SvgLoader";

const BANNER_BASE_CLASS = "shadow rounded bg-amber-50 py-3 px-3";
const MESSAGE_BASE_CLASS = "flex-initial ml-2 text-amber-800";
const ICON_BASE_CLASS = "w-6 h-6 fill-amber-400";

const BANNER_TYPE_STYLES = {
  danger: "rounded shadow bg-red-50 py-3 px-3",
};

const ICON_TYPE_STYLES = {
  danger: "w-5 h-5 fill-red-400",
};

const MESSAGE_TYPE_STYLES = {
  danger: "flex-initial ml-3 text-red-800",
};

const WarningBanner = ({
  children,
  title = undefined,
  text,
  closeBanner = false,
  type,
  noPadding = false,
}) => {
  return (
    <div
      className={noPadding ? "c_warning-banner" : "c_warning-banner px-3 py-1"}
    >
      <div className={BANNER_TYPE_STYLES[type] || BANNER_BASE_CLASS}>
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex items-center">
            <div className="flex-1">
              <SvgLoader
                id={type === "danger" ? "CircleInfo" : "Warning"}
                className={ICON_TYPE_STYLES[type] || ICON_BASE_CLASS}
              />
            </div>
            <div>
              {typeof title !== "undefined" ? (
                <div
                  className={MESSAGE_TYPE_STYLES[type] || MESSAGE_BASE_CLASS}
                >
                  <p className="font-bold">{title}</p>
                  {text && <p className="opacity-80">{text}</p>}
                  {children && children}
                </div>
              ) : (
                <div
                  className={MESSAGE_TYPE_STYLES[type] || MESSAGE_BASE_CLASS}
                >
                  {children || text}
                </div>
              )}
            </div>
          </div>

          {closeBanner ? <div>{closeBanner}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default WarningBanner;
